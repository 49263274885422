<template>
    <div>
        <CRow>
            <CCol sm="6" lg="3">
                <CWidgetProgress v-if="overview"
                                 :header=overview.users_count.NumberOfUsers
                                 text="Active Users."
                                 footer=""
                                 color="gradient-info"
                />
            </CCol>
            <CCol sm="6" lg="3">

                <CWidgetProgress v-if="overview"
                                 :header=overview.users_queue.UserQueueNum
                                 text="Upcoming Users."
                                 footer=""
                                 color="gradient-info"
                />
            </CCol>
        </CRow>
        <CCard>
            <CCardHeader>
                <CCol sm="6">
                    Site health
                    <CSelect v-if="all_sites"
                             label="Select Site"
                             :options="all_sites"
                             horizontal
                             :value.sync="rctl_id"
                             @update:value="getAPHealth(); getSWHealth();"
                    />
                </CCol>

            </CCardHeader>


            <CRow>

                <CCol sm="6">
                    <h5 style="padding-left: 30px"> AccessPoint Health </h5>
                    <CChartLine
                            :datasets="site_statistics"
                            title="AccessPoints"
                            heading="AccessPoints"
                            labels="['Online', 'Offline', 'Flagged']"
                    />
                </CCol>
                <CCol sm="6">
                    <h5 style="padding-left: 30px"> Switches Health </h5>
                    <CChartLine
                            :datasets="switch_statistics"
                            title="Switches"
                            heading="Switches"
                            labels="['Online', 'Offline', 'Flagged']"
                    />
                </CCol>
            </CRow>
        </CCard>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        Latest Users

                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                hover
                                striped
                                sorter
                                columnFilter
                                itemsPerPageSelect
                                responsive
                                :items="users_list"
                                :fields="users_field"
                                :items-per-page="3"
                                clickable-rows
                                :active-page="activePage"
                                :pagination="{ doubleArrows: true, align: 'center'}"
                        >
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        Latest Logs

                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                hover
                                striped
                                sorter
                                columnFilter
                                itemsPerPageSelect
                                responsive
                                :items="logs"
                                :fields="fields"
                                :items-per-page="3"
                                clickable-rows
                                :active-page="activePage"
                                :pagination="{ doubleArrows: true, align: 'center'}"
                        >
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import axios from 'axios';
    import {CChartLine} from '@coreui/vue-chartjs'

    export default {

        name: 'Dashboard',
        components: {CChartLine},
        data() {
            return {

                overview: null,
                selected: 'Month',
                logs: [],
                all_sites: [],
                critical_logs: [],
                users_field: [{key: 'usr_email', label: 'User'}],
                site_statistics: [],
                users_list: [],
                switch_statistics: [],
                rctl_id: null,
                fields: [
                    {key: 'usr_email', label: 'User'},
                    {key: 'log_level', label: "Log Level"},
                    {key: 'log_timestamp', label: "TimeStamp"},
                    {key: 'log_comment', label: "Comment"}
                ]
            }
        },
        methods: {
            getAllSites() {
                var query = "SELECT * FROM ruckus_controllers " +
                    " JOIN ruckus_zone USING (rctl_id)" +
                    " JOIN properties USING (pro_id)"

                axios.get(this.$baseURL + '/generic/executesql?sql_string=' + query,
                    {
                        headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                    }).then((res) => {

                        res.data.forEach(e => {
                            this.all_sites.push({value: e.rctl_id, label: e.pro_shortname})
                        })
                        console.log(this.all_sites);
                    }
                )
            },
            getOverview() {
                axios.get(this.$baseURL + '/statistics/overview')
                    .then(res => {
                        this.overview = res.data;
                        this.logs = res.data.latest_logs;

                    }).catch((error) => this.$toast.error(error))
            },
            getAPHealth() {

                var query = "SELECT * FROM site_ap_health " +
                    "WHERE rctl_id = " + this.rctl_id +
                    " ORDER BY site_ap_health.timestamp DESC LIMIT 30"


                axios.get(this.$baseURL + '/generic/executesql-admin?sql_string=' + query,
                    {
                        headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                    }).then((res) => {
                        var online_aps = [];
                        var offline_aps = [];
                        var flagged_aps = [];
                        res.data.forEach(e => {

                            online_aps.push(e.online_aps);
                            offline_aps.push(e.offline_aps);
                            flagged_aps.push(e.flagged_aps);
                        })

                        this.site_statistics = [
                            {
                                label: 'Offline',
                                backgroundColor: 'rgba(238,8,8,0.9)',
                                data: offline_aps
                            },
                            {
                                label: 'Online',
                                backgroundColor: 'rgba(0,255,134,0.9)',
                                data: online_aps
                            },

                            {
                                label: 'Flagged',
                                backgroundColor: 'rgba(219,130,60,0.9)',
                                data: flagged_aps
                            }
                        ]
                    }
                )

            },
            getSWHealth() {

                var query = "SELECT * FROM site_sw_health " +
                    "WHERE rctl_id = " + this.rctl_id +
                    " ORDER BY site_sw_health.timestamp DESC LIMIT 30 "


                axios.get(this.$baseURL + '/generic/executesql-admin?sql_string=' + query,
                    {
                        headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                    }).then((res) => {
                        var online_aps = [];
                        var offline_aps = [];
                        var flagged_aps = [];
                        res.data.forEach(e => {

                            online_aps.push(e.online_sws);
                            offline_aps.push(e.offline_sws);
                            flagged_aps.push(e.flagged_sws);
                        })

                        this.switch_statistics = [
                            {
                                label: 'Offline',
                                backgroundColor: 'rgba(238,8,8,0.9)',
                                data: offline_aps
                            },

                            {
                                label: 'Online',
                                backgroundColor: 'rgba(0,255,134,0.9)',
                                data: online_aps
                            },

                            {
                                label: 'Flagged',
                                backgroundColor: 'rgba(219,130,60,0.9)',
                                data: flagged_aps
                            }
                        ]
                    }
                )

            },
            getLatestUsers() {

                var query = "SELECT * FROM user " +
                    "WHERE usr_status is not null" +
                    " ORDER BY user.usr_activity DESC" +
                    " LIMIT 50 "


                axios.get(this.$baseURL + '/generic/executesql?sql_string=' + query,
                    {
                        headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                    }
                ).then((res) => {
                        this.users_list = res.data;
                    }
                )

            }
        },
        mounted() {
            // this.getAllSites();
            // this.getOverview();
            // this.getLatestUsers();


        },
        computed: {
            defaultDatasets() {
                return [
                    {
                        label: 'Data One',
                        backgroundColor: 'rgb(228,102,81,0.9)',
                        data: [30, 39, 10, 50, 30, 70, 35]
                    },
                    {
                        label: 'Data Two',
                        backgroundColor: 'rgb(0,216,255,0.9)',
                        data: [39, 80, 40, 35, 40, 20, 45]
                    }
                ]
            }
        },
    }
</script>
